export default {
  name: '',
  components: {},
  props: {},

  data() {
    return {};
  },

  computed: {},
  watch: {},

  created() {// this.open()
  },

  mounted() {},

  methods: {
    open() {
      let href = 'https://app.mokahr.com/social-recruitment/nhbio/100259'; // let href = 'https://app.mokahr.com/social-recruitment/nhbio/42077'

      window.open(href, '_blank');
    }

  }
};